import { useContext, useState, useEffect } from 'react'
import { useTheme, ThemeContext } from 'styled-components'
import {
  PlusOutlined,
  EllipsisOutlined,
  LinkOutlined,
  DownOutlined,
  EditFilled,
  LoadingOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import {
  Button as AntButton,
  Table,
  Tag,
  Menu,
  Modal,
  Dropdown,
  Form,
  Input,
  Breadcrumb,
  Select,
  Collapse,
} from 'antd'
import {
  fetchVenue,
  fetchConstsByName,
  updateVenue,
  updateVenueSettings,
  updateVenueHomepageSettings,
  createVenueAvailableLesson,
  deleteVenueAvailableLesson,
  fetchVenueHomepageCallouts,
  createVenueHomepageCallout,
  updateVenueHomepageCallout,
  deleteVenueHomepageCallout,
  updateVenueHomepageCalloutsOrder,
  updateBenefitVenueSettings,
  fetchVenueResources,
  fetchBenefitBeneficiaryGroups,
  updateBenefitVenuePermittedResources,
  updateBenefitVenuePermittedBeneficiaryGroups,
} from '../../../actions/admin'
import { Link, useParams } from 'react-router-dom'
import { useStatus, useStatusMsg } from '../../../reducers'
import { useDispatch } from 'react-redux'
import { clearStatus } from '../../../actions/status'
import { actions as acts, paths } from '../../../constants'
import { Settings } from '../../../components/settings'
import {
  Noner,
  ID,
  Label,
  PaddedContainer,
  Title,
  Head,
  Button,
  Cta as CtaButton,
} from '../../../components/common'
import Attr from '../../../components/Attr'
import { MultiImageUpload } from '../../../components/image-upload'
import CountrySelector from '../../../components/CountrySelector'
import StateSelector from '../../../components/StateSelector'
import { SportSelect, SportDisplay } from '../../../components/sport'
import Address from '../../../components/Address'
import ImagePreview from '../../../components/ImagePreview'
import { MediumInfo } from '../../../components/info'
import formatUtils from '../../../util/format'
import urlUtils from '../../../util/url'
import { formatDateTimeTz } from '../../../util/time'
import { BookingMetrics } from './Org'
import SingleImageEditor from '../../../components/SingleImageEditor'
import { Reorderable } from '../../../components/Reorderable'
import { PackageSets } from './package'

const { Option } = Select

// Venue editor component for editing venue details including name, address, images, contact info, and venue info
const VenueEditor = ({ venue, onChange }) => {
  const theme = useTheme(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  const { name, address, images, phoneNumber, email, officialWebsiteUrl, instagramUrl, info } =
    venue
  const { country, line1, line2, city, state, postcode, lat, lon, url } = address ?? {}
  const { attributes } = info ?? { attributes: { numberOfCourts: 0, surfaceTypes: [], sports: [] } }

  const change = update => {
    changer({ ...venue, ...update })
  }

  const changeAddress = update => {
    changer({ ...venue, address: { ...venue.address, ...update } })
  }

  const changeImages = update => {
    changer({ ...venue, images: update })
  }

  const changeInfo = update => {
    changer({
      ...venue,
      info: {
        ...venue.info,
        attributes: {
          ...(venue.info?.attributes || {}),
          ...update,
        },
      },
    })
  }

  const handleChangeUrl =
    key =>
    ({ target }) => {
      let newValue = target.value.trim()

      // Remove existing protocol
      newValue = newValue.replace(/^(https?:\/\/)/, '')

      // Prepend https:// if the value is not empty
      if (newValue) {
        newValue = `https://${newValue}`
      }

      target.value = newValue
      change({ [key]: newValue })
    }
  const validateHttpProtocol = (_, value) => {
    if (value && !value.startsWith('https://')) {
      return Promise.reject('Please provide a URL with HTTPS protocol.')
    }
    return Promise.resolve()
  }

  return (
    <Form layout="vertical">
      <Form.Item name="name" label={<Label>Name</Label>} initialValue={name}>
        <Input placeholder="" onChange={e => change({ name: e.target.value })} value={name} />
      </Form.Item>
      <Attr name="Images">
        <MultiImageUpload images={images} onChange={newImages => changeImages(newImages)} />
      </Attr>
      <Form.Item name="phone-number" label={<Label>Phone number</Label>} initialValue={phoneNumber}>
        <Input
          placeholder=""
          onChange={e => change({ phoneNumber: e.target.value })}
          value={phoneNumber}
        />
      </Form.Item>
      <Form.Item name="email" label={<Label>Email</Label>} initialValue={email}>
        <Input placeholder="" onChange={e => change({ email: e.target.value })} value={email} />
      </Form.Item>
      <Form.Item
        name="official-website-url"
        label={<Label>Official website URL</Label>}
        initialValue={officialWebsiteUrl}
        rules={[
          {
            type: 'url',
            message: 'This field must be a valid url.',
          },
          {
            validator: validateHttpProtocol,
          },
        ]}
      >
        <Input
          placeholder=""
          onChangeCapture={handleChangeUrl('officialWebsiteUrl')}
          value={officialWebsiteUrl}
        />
      </Form.Item>
      <Form.Item
        name="instagram-url"
        label={<Label>Instagram URL</Label>}
        initialValue={instagramUrl}
        rules={[
          {
            type: 'url',
            message: 'This field must be a valid url.',
          },
          {
            validator: validateHttpProtocol,
          },
        ]}
      >
        <Input
          placeholder=""
          onChangeCapture={handleChangeUrl('instagramUrl')}
          value={instagramUrl}
        />
      </Form.Item>
      <Attr name="Address">
        <Form.Item name="country" label={<Label>Country</Label>} initialValue={country}>
          <CountrySelector
            id="country"
            onChange={v => changeAddress({ country: v })}
            value={country}
            style={{ width: theme.width[5] }}
          />
        </Form.Item>

        <Form.Item name="line1" label={<Label>Line 1</Label>} initialValue={line1}>
          <Input
            placeholder=""
            onChange={e => changeAddress({ line1: e.target.value })}
            value={line1}
          />
        </Form.Item>
        <Form.Item name="line2" label={<Label>Line 2</Label>} initialValue={line2}>
          <Input
            placeholder=""
            onChange={e => changeAddress({ line2: e.target.value })}
            value={line2}
          />
        </Form.Item>

        <Form.Item name="city" label={<Label>City</Label>} initialValue={city}>
          <Input
            placeholder=""
            onChange={e => changeAddress({ city: e.target.value })}
            style={{ width: theme.width[5] }}
            value={city}
          />
        </Form.Item>

        <Form.Item name="state" label={<Label>State</Label>} initialValue={state}>
          <StateSelector
            id="state"
            onChange={v => changeAddress({ state: v })}
            value={state}
            style={{ width: theme.width[5] }}
          />
        </Form.Item>

        <Form.Item name="postcode" label={<Label>Postcode</Label>} initialValue={postcode}>
          <Input
            placeholder=""
            onChange={e => changeAddress({ postcode: e.target.value })}
            value={postcode}
          />
        </Form.Item>

        <Form.Item name="lat" label={<Label>Latitude</Label>} initialValue={lat}>
          <Input
            placeholder=""
            onChange={e => changeAddress({ lat: e.target.value })}
            value={lat}
          />
        </Form.Item>

        <Form.Item name="lon" label={<Label>Longitude</Label>} initialValue={lon}>
          <Input
            placeholder=""
            onChange={e => changeAddress({ lon: e.target.value })}
            value={lon}
          />
        </Form.Item>

        <Form.Item name="url" label={<Label>Address URL</Label>} initialValue={url}>
          <Input
            placeholder=""
            onChange={e => changeAddress({ url: e.target.value })}
            value={url}
          />
        </Form.Item>
      </Attr>
      <div style={{ marginBottom: theme.spacing[3] }}>
        <Collapse>
          <Collapse.Panel header="Venue Information" key="venue-info">
            <div style={{ padding: theme.spacing[1] }}>
              <Form.Item
                name="sports"
                label={<Label>Sports</Label>}
                initialValue={attributes?.sports}
              >
                <SportSelect
                  mode="multiple"
                  placeholder="Select sports"
                  onChange={values => changeInfo({ sports: values })}
                  value={attributes?.sports}
                />
              </Form.Item>
              <Form.Item
                name="numberOfCourts"
                label={<Label>Number of Courts</Label>}
                initialValue={attributes?.numberOfCourts}
              >
                <Input
                  type="number"
                  min={0}
                  placeholder="Enter number of courts"
                  onChange={e => changeInfo({ numberOfCourts: parseInt(e.target.value, 10) })}
                  value={attributes?.numberOfCourts}
                />
              </Form.Item>
              <Form.Item
                name="surfaceTypes"
                label={<Label>Surface Types</Label>}
                initialValue={attributes?.surfaceTypes}
              >
                <Select
                  mode="multiple"
                  placeholder="Select surface types"
                  onChange={values => changeInfo({ surfaceTypes: values })}
                  value={attributes?.surfaceTypes}
                >
                  <Option value="CLAY">Clay</Option>
                  <Option value="HARD">Hard</Option>
                  <Option value="GRASS">Grass</Option>
                  <Option value="ARTIFICIAL_GRASS">Artificial Grass</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="venueType"
                label={<Label>Venue Type</Label>}
                initialValue={attributes?.venueType}
              >
                <Select
                  placeholder="Select venue type"
                  onChange={value => changeInfo({ venueType: value })}
                  value={attributes?.venueType}
                >
                  <Option value="INDOOR">Indoor</Option>
                  <Option value="OUTDOOR">Outdoor</Option>
                  <Option value="MIXED_USE">Mixed-Use</Option>
                </Select>
              </Form.Item>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </Form>
  )
}

// Edit venue modal component for updating venue details
export const EditVenue = ({ complete, venue }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}
  const [ven, setVen] = useState(venue)
  const status = {
    update: useStatus(acts.UPDATE_VENUE),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_VENUE))
    }
  }, [])

  useStatusMsg(status.update, { error: 'Failed to update venue' })

  const onOk = () => {
    dispatch(
      updateVenue({
        venueId: ven.id,
        venue: {
          name: ven.name,
          address: ven.address,
          images: ven.images,
          phoneNumber: ven.phoneNumber,
          email: ven.email,
          officialWebsiteUrl: ven.officialWebsiteUrl,
          instagramUrl: ven.instagramUrl,
          info: ven.info,
        },
      }),
    )
      .then(res => completer(res))
      .catch(() => {})
  }

  // TODO basic form completion; we could do with something more robust
  const formComplete =
    ven.address &&
    [ven.address.line1, ven.address.country, ven.address.city, ven.address.postcode].reduce(
      (acc, curr) => curr && acc,
      true,
    )

  return (
    <Modal
      title={'Edit venue'}
      visible
      okText={'Save'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.update.pending || false}
    >
      <VenueEditor venue={ven} onChange={updatedVenue => setVen(updatedVenue)} />
    </Modal>
  )
}

export const VenueActionsMenu = ({ venue, onEdit }) => {
  const dispatch = useDispatch()
  const status = useStatus(acts.UPDATE_VENUE)

  useStatusMsg(status, {
    error: `Failed to update venue: ${status.error}`,
    success: 'Venue updated successfully',
    pending: 'Updating venue ...',
  })

  const handleChangeVenueStatus = venue => {
    dispatch(
      updateVenue({
        venueId: venue.id,
        venue: {
          status: venue.status === 'PUBLISHED' ? 'UNPUBLISHED' : 'PUBLISHED',
        },
      }),
    ).then(() => {
      if (typeof onEdit === 'function') {
        onEdit()
      }
    })
  }
  return (
    <Menu>
      <Menu.Item key="edit" onClick={() => onEdit(venue)}>
        Edit
      </Menu.Item>
      <Menu.Item key="publish" onClick={() => handleChangeVenueStatus(venue)}>
        {venue.status === 'PUBLISHED' ? 'Unpublish' : 'Publish'}
      </Menu.Item>
    </Menu>
  )
}

// expects homepage to be present and expanded on venue
const VenueHomepageSettings = ({ venue, onChange }) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [allSettings, setAllSettings] = useState(null)
  const [editing, setEditing] = useState(false)
  const [settings, setSettings] = useState(venue.homepage.settings)
  const status = {
    update: useStatus(acts.UPDATE_VENUE_SETTINGS),
    fetchConsts: useStatus(acts.FETCH_CONSTS_BY_NAME),
  }

  useEffect(() => {
    dispatch(fetchConstsByName({ name: 'venue-homepage' })).then(consts =>
      setAllSettings(consts.settings),
    )

    return () => {
      dispatch(clearStatus(acts.UPDATE_VENUE_HOMEPAGE_SETTINGS))
      dispatch(clearStatus(acts.FETCH_CONSTS_BY_NAME))
    }
  }, [])

  useStatusMsg(status.fetchConsts, {
    error: 'Failed to fetch homepage settings',
  })

  if (status.fetchConsts.pending) {
    return <LoadingOutlined />
  }

  if (!allSettings || Object.keys(allSettings).length === 0) {
    return <i>No homepage settings available</i>
  }

  const onSaveSettings = () => {
    dispatch(updateVenueHomepageSettings({ venueSig: venue.id, settings }))
      .then(updatedSettings => {
        setEditing(false)
        changer(updatedSettings)
      })
      .catch(() => {})
  }

  const onCancelEdit = () => {
    setEditing(false)
    setSettings(venue.homepage.settings)
  }

  let buttons = (
    <Button onClick={() => setEditing(true)}>
      <EditFilled /> Edit
    </Button>
  )
  if (editing) {
    buttons = (
      <div>
        <Button
          style={{ marginRight: theme.spacing[2] }}
          disabled={status.update.pending}
          onClick={onCancelEdit}
        >
          Cancel
        </Button>
        <CtaButton onClick={onSaveSettings} disabled={status.update.pending}>
          {status.update.pending ? 'Saving...' : 'Save'}
        </CtaButton>
      </div>
    )
  }
  return (
    <MediumInfo title="Homepage settings" style={{ marginTop: 0 }} button={buttons}>
      <div style={{ maxWidth: '700px' }}>
        <Settings
          allSettings={allSettings}
          settings={settings}
          editing={editing}
          onChange={updatedSettings => setSettings(updatedSettings)}
        />
      </div>
    </MediumInfo>
  )
}

const VenueHomepage = ({ venue, onChange }) => {
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  if (!venue.homepage) {
    return <Noner none="No homepage"></Noner>
  }
  const updateSettings = updatedSettings => {
    changer({
      ...venue,
      homepage: {
        ...venue.homepage,
        settings: updatedSettings,
      },
    })
  }
  return (
    <div style={{ paddingLeft: theme.spacing[3] }}>
      <VenueHomepageCallouts venue={venue} />
      <VenueHomepageSettings venue={venue} onChange={updateSettings} />
    </div>
  )
}

const VenueHomepageCallouts = ({ venue }) => {
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const [callouts, setCallouts] = useState(null)
  const [openEditor, setOpenEditor] = useState(false)
  const [editingCallout, setEditingCallout] = useState(false)
  const [currentCallout, setCurrentCallout] = useState(null)

  useEffect(() => {
    dispatch(
      fetchVenueHomepageCallouts({ venueSig: venue.id, venueHomepage: venue.homepage.id }),
    ).then(callouts => setCallouts(callouts.sort((a, b) => a.order - b.order)))
  }, [venue])

  const updateCallouts = updatedCallouts => {
    setCallouts(updatedCallouts)
    dispatch(
      updateVenueHomepageCalloutsOrder({
        venueSig: venue.id,
        callouts: updatedCallouts,
      }),
    )
  }

  const onRemoveCallout = callout => {
    setCallouts(prev => prev.filter(c => c.id !== callout.id))
  }

  let display = <Noner none="No callouts"></Noner>
  if (Array.isArray(callouts) && callouts.length > 0) {
    display = (
      <Reorderable
        items={callouts}
        keyExtractor={c => c.id}
        onChange={updatedCallouts => updateCallouts(updatedCallouts)}
        renderItem={c => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              backgroundColor: theme.color.white,
              padding: theme.spacing[2],
              borderRadius: theme.br[2],
            }}
          >
            <span style={{ marginRight: theme.spacing[2] }}>{c.title}</span>
            <Dropdown
              overlay={
                <CalloutActionMenu
                  venue={venue}
                  callout={c}
                  onRemove={onRemoveCallout}
                  onEdit={() => {
                    setEditingCallout(true)
                    setCurrentCallout(c)
                    setOpenEditor(true)
                  }}
                />
              }
              trigger={['click']}
            >
              <Button>
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </div>
        )}
      />
    )
  }

  const button = (
    <Button
      onClick={() => {
        setEditingCallout(false)
        setCurrentCallout(null)
        setOpenEditor(true)
      }}
    >
      <PlusOutlined /> Add callout
    </Button>
  )
  return (
    <MediumInfo title="Callouts" style={{ marginBottom: theme.spacing[3] }} button={button}>
      {display}
      {openEditor && (
        <CalloutEditorModal
          venue={venue}
          complete={callout => {
            setOpenEditor(false)
            setCallouts(prev => {
              if (!callout) {
                return prev
              }
              return [...prev.filter(c => c.id !== callout.id), callout].sort(
                (a, b) => a.order - b.order,
              )
            })
          }}
          isEdit={editingCallout}
          existingCallout={currentCallout}
        />
      )}
    </MediumInfo>
  )
}

export const CalloutActionMenu = ({ venue, callout, onRemove, onEdit }) => {
  const dispatch = useDispatch()
  const removeStatus = useStatus(acts.DELETE_VENUE_HOMEPAGE_CALLOUT)

  useStatusMsg(removeStatus, {
    pending: 'Removing callout...',
    error: 'Failed to remove callout',
    success: 'callout removed',
  })

  useEffect(() => () => dispatch(clearStatus(acts.DELETE_VENUE_HOMEPAGE_CALLOUT)), [])

  const handleActionMenu = e => {
    if (e.key === 'edit') {
      if (typeof onEdit === 'function') {
        onEdit()
      }
    }
    if (e.key === 'remove') {
      Modal.confirm({
        title: 'Remove callout?',
        okText: 'Remove',
        okButtonProps: {
          danger: true,
          loading: removeStatus.pending || false,
          disabled: removeStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to remove this callout?</p>
          </div>
        ),
        onOk: () => {
          dispatch(
            deleteVenueHomepageCallout({
              venueSig: venue.id,
              venueHomepage: venue.homepage.id,
              calloutId: callout.id,
            }),
          ).then(res => {
            if (typeof onRemove === 'function') {
              onRemove(callout)
            }
          })
        },
      })
    }
  }
  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="remove" danger>
        Remove
      </Menu.Item>
    </Menu>
  )
}

const CalloutEditorModal = ({ venue, complete, existingCallout, isEdit }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}
  const [title, setTitle] = useState(existingCallout ? existingCallout.title : '')
  const [url, setUrl] = useState(existingCallout ? existingCallout.url : '')
  const [image, setImage] = useState(existingCallout ? existingCallout.image : null)
  const createStatus = useStatus(acts.CREATE_VENUE_HOMEPAGE_CALLOUT)
  const updateStatus = useStatus(acts.UPDATE_VENUE_HOMEPAGE_CALLOUT)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_VENUE_HOMEPAGE_CALLOUT))
      dispatch(clearStatus(acts.UPDATE_VENUE_HOMEPAGE_CALLOUT))
    }
  }, [])

  useStatusMsg(createStatus, {
    error: e => `Failed to add callout: ${e}`,
    success: 'Callout created',
    pending: 'Creating callout...',
  })

  useStatusMsg(updateStatus, {
    error: e => `Failed to update callout: ${e}`,
    success: 'Callout updated',
    pending: 'Updating callout...',
  })

  const onOk = () => {
    let action
    if (isEdit) {
      const dataToUpdate = {
        venueSig: venue.id,
        callout: { title, url },
        calloutId: existingCallout.id,
      }
      if (image !== existingCallout.image) {
        dataToUpdate.image = image
      }
      action = updateVenueHomepageCallout(dataToUpdate)
    } else {
      action = createVenueHomepageCallout({
        venueSig: venue.id,
        callout: { title, url },
        image,
      })
    }

    dispatch(action)
      .then(res => completer(res))
      .catch(() => {})
  }

  return (
    <Modal
      title={'Add callout'}
      open
      okText={'Confirm'}
      onOk={onOk}
      onCancel={() => completer(existingCallout)}
      confirmLoading={createStatus.pending || updateStatus.pending || false}
    >
      <Form layout="vertical">
        <Form.Item name="title" label="Title" initialValue={title}>
          <Input
            placeholder="Promotion title"
            onChange={e => setTitle(e.target.value)}
            value={title}
          />
        </Form.Item>
        <Form.Item name="url" label="Url" initialValue={url}>
          <Input
            placeholder="www.some-url.com"
            onChange={e => setUrl(e.target.value)}
            value={url}
          />
        </Form.Item>
        <Form.Item name="image" label="Image" initialValue={image}>
          <SingleImageEditor
            actionText="Select image"
            onChange={img => setImage(img)}
            img={image}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const VenueSettings = ({ venue, onChange }) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [allSettings, setAllSettings] = useState(null)
  const [editing, setEditing] = useState(false)
  const [settings, setSettings] = useState(venue.settings)
  const status = {
    update: useStatus(acts.UPDATE_VENUE_SETTINGS),
    fetchConsts: useStatus(acts.FETCH_CONSTS_BY_NAME),
  }

  useEffect(() => {
    dispatch(fetchConstsByName({ name: 'venue' })).then(consts => setAllSettings(consts.settings))

    return () => {
      dispatch(clearStatus(acts.UPDATE_VENUE_SETTINGS))
      dispatch(clearStatus(acts.FETCH_CONSTS_BY_NAME))
    }
  }, [])

  useStatusMsg(status.fetchConsts, {
    error: 'Failed to fetch settings',
  })

  if (status.fetchConsts.pending) {
    return <LoadingOutlined />
  }

  if (!allSettings || Object.keys(allSettings).length === 0) {
    return <i>No settings available</i>
  }

  const onSaveSettings = () => {
    dispatch(updateVenueSettings({ venueSig: venue.id, settings }))
      .then(updatedSettings => {
        setEditing(false)
        changer(updatedSettings)
      })
      .catch(() => {})
  }

  const onCancelEdit = () => {
    setEditing(false)
    setSettings(venue.settings)
  }

  let buttons = (
    <Button onClick={() => setEditing(true)}>
      <EditFilled /> Edit
    </Button>
  )
  if (editing) {
    buttons = (
      <div>
        <Button
          style={{ marginRight: theme.spacing[2] }}
          disabled={status.update.pending}
          onClick={onCancelEdit}
        >
          Cancel
        </Button>
        <CtaButton onClick={onSaveSettings} disabled={status.update.pending}>
          {status.update.pending ? 'Saving...' : 'Save'}
        </CtaButton>
      </div>
    )
  }
  return (
    <MediumInfo title="Settings" style={{ marginTop: 0 }} button={buttons}>
      <div style={{ maxWidth: '700px' }}>
        <Settings
          allSettings={allSettings}
          settings={settings}
          editing={editing}
          onChange={updatedSettings => setSettings(updatedSettings)}
        />
      </div>
    </MediumInfo>
  )
}

export const VenueImages = ({ venue }) => {
  const { images } = venue
  let display = <Noner></Noner>
  if (Array.isArray(images) && images.length > 0) {
    display = images.map(i => {
      return (
        <div key={i.id} style={{ marginRight: '.5em' }}>
          <ImagePreview
            key={i.id}
            asset={i.asset}
            width="100px"
            height="100px"
            style={{ objectFit: 'cover' }}
          />
        </div>
      )
    })
  }
  return <div style={{ display: 'flex' }}>{display}</div>
}

export const VenueAddress = ({ venue }) => {
  const { address } = venue
  const addr = { ...address, addressLine1: address?.line1, addressLine2: address?.line2 }
  return (
    <div>
      <Address address={addr} />
      <pre>
        <div>
          <b>url</b>{' '}
          <Noner none="none">
            {address?.url ? (
              <a href={address?.url}>
                View <LinkOutlined />
              </a>
            ) : null}
          </Noner>
        </div>
        <div>
          <b>lat</b> <Noner none="none">{address?.lat}</Noner>
        </div>
        <div>
          <b>lon</b> <Noner none="none">{address?.lon}</Noner>
        </div>
      </pre>
    </div>
  )
}

const VenueResources = ({ venue }) => {
  const theme = useTheme(ThemeContext)
  let display = <Noner>{null}</Noner>
  if (Array.isArray(venue.resources) && venue.resources.length > 0) {
    display = venue.resources
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(r => {
        let hourlyPriceDisplay = <Noner none="No hourly price">{null}</Noner>
        if (typeof r.baseHourlyPriceCents === 'number') {
          hourlyPriceDisplay = `${formatUtils.centsToDollars(r.baseHourlyPriceCents)}/hour`
        }
        return (
          <div key={r.id} style={{ marginBottom: theme.spacing[2] }}>
            {r.name}{' '}
            <Tag title="Resource type" style={{ marginLeft: theme.spacing[1] }}>
              {r.type}
            </Tag>{' '}
            <Tag title="Pricing type">{r.pricingType}</Tag> <span>{hourlyPriceDisplay}</span>
          </div>
        )
      })
  }
  return <div>{display}</div>
}

export const VenueAvailableLessonsActionMenu = ({ availableLesson, onRemove }) => {
  const dispatch = useDispatch()
  const removeStatus = useStatus(acts.DELETE_VENUE_AVAILABLE_LESSON)

  useStatusMsg(removeStatus, {
    pending: 'Removing available lesson...',
    error: 'Failed to remove available lesson',
    success: 'Available lesson removed',
  })

  useEffect(() => () => dispatch(clearStatus(acts.DELETE_VENUE_AVAILABLE_LESSON)), [])

  const handleActionMenu = e => {
    if (e.key === 'remove') {
      Modal.confirm({
        title: 'Remove available lesson?',
        okText: 'Remove',
        okButtonProps: {
          danger: true,
          loading: removeStatus.pending || false,
          disabled: removeStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to remove this available lesson?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(
            deleteVenueAvailableLesson({
              venueSig: availableLesson.venue,
              availableLessonId: availableLesson.id,
            }),
          ).then(() => {
            if (typeof onRemove === 'function') {
              onRemove(availableLesson)
            }
          })
        },
      })
    }
  }
  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="remove" danger>
        Remove
      </Menu.Item>
    </Menu>
  )
}

const availableLessonCols = onRemove => [
  {
    title: 'Sport',
    dataIndex: 'sport',
    key: 'sport',
    render: (val, record) => {
      return (
        <div>
          <SportDisplay sport={val} />
        </div>
      )
    },
  },
  {
    title: 'Notify emails',
    dataIndex: 'notifyEmails',
    key: 'notifyEmails',
    render: (val, record) => {
      if (!Array.isArray(val) || val.length === 0) {
        ;<Noner none="No notify emails"></Noner>
      }
      return (
        <div>
          {val.map(e => (
            <div key={e}>{e}</div>
          ))}
        </div>
      )
    },
  },
  {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDateTimeTz(val)}</div>,
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    render: (val, record) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <Dropdown
            overlay={
              <VenueAvailableLessonsActionMenu availableLesson={record} oneRemove={onRemove} />
            }
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  },
]

const AddAvailableLessonModal = ({ venue, complete }) => {
  const dispatch = useDispatch()
  const theme = useTheme(ThemeContext)
  const completer = typeof complete === 'function' ? complete : () => {}
  const [sport, setSport] = useState(null)
  const [notifyEmails, setNotifyEmails] = useState([])
  const status = useStatus(acts.CREATE_VENUE_AVAILABLE_LESSON)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_VENUE_AVAILABLE_LESSON))
    }
  }, [])

  useStatusMsg(status, { error: e => `Failed to add available lesson: ${e}` })

  const onOk = () => {
    const emails = notifyEmails
      .filter(Boolean)
      .map(e => e.trim())
      .filter(Boolean)
    dispatch(createVenueAvailableLesson({ venueSig: venue.id, sport, notifyEmails: emails }))
      .then(res => completer(res))
      .catch(() => {})
  }

  return (
    <Modal
      title={'Add available lesson'}
      visible
      okText={'Add'}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.pending || false}
    >
      <Attr
        name="Sport"
        description="Certain sports will appear on certain brands e.g. racquet sports in BTL, golf in BTB"
      >
        <SportSelect
          id="sport"
          onChange={s => setSport(s)}
          value={sport}
          style={{ width: theme.width[5] }}
          placeholder="Select a sport"
        />
      </Attr>
      <Attr
        name="Notification emails"
        description="Emails to be notified when a user requests a lesson. Concierge is automatically notified. Venue admin/operator emails are generally added here"
      >
        <div style={{ marginBottom: theme.spacing[3] }}>
          {notifyEmails.map((e, i) => (
            <div
              key={i}
              style={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing[2] }}
            >
              <Input
                value={e}
                placeholder="email@example.com"
                onChange={e =>
                  setNotifyEmails(prev => {
                    const next = [...prev]
                    next[i] = e.target.value
                    return next
                  })
                }
              />
              <AntButton
                danger
                type="link"
                onClick={() => setNotifyEmails(prev => prev.filter((_, j) => j !== i))}
              >
                Remove
              </AntButton>
            </div>
          ))}
        </div>
        <Button onClick={() => setNotifyEmails(prev => [...prev, ''])}>
          <PlusOutlined /> Add email
        </Button>
      </Attr>
    </Modal>
  )
}

const VenueAvailableLessons = ({ venue, onAdd, onRemove }) => {
  const [adding, setAdding] = useState(false)
  const theme = useTheme(ThemeContext)
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: theme.spacing[2] }}>
        <Button onClick={() => setAdding(true)}>
          <PlusOutlined /> Add available lesson
        </Button>
      </div>
      <Table
        size="small"
        columns={availableLessonCols({ onRemove })}
        dataSource={venue.availableLessons}
        pagination={false}
      />
      {adding && (
        <AddAvailableLessonModal
          venue={venue}
          complete={availableLesson => {
            if (availableLesson) {
              onAdd(availableLesson)
            }
            setAdding(false)
          }}
        />
      )}
    </div>
  )
}

const benefitVenueCols = ({ openSettings, setBenefitVenue }) => [
  {
    title: 'Facilitated for',
    dataIndex: 'benefit',
    key: 'benefit',
    render: (val, record) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{val.name}</span>
          <small style={{ color: 'gray' }}>{record.id}</small>
        </div>
      )
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (val, record) => {
      return (
        <div>
          <span>{val}</span>
        </div>
      )
    },
  },
  {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDateTimeTz(val)}</div>,
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    render: (val, record) => {
      const handleActionMenu = e => {
        if (e.key === 'settings') {
          if (typeof openSettings === 'function' && typeof setBenefitVenue === 'function') {
            openSettings()
            setBenefitVenue(record)
          }
        }
      }
      return (
        <div style={{ textAlign: 'center' }}>
          <Dropdown
            overlay={
              <Menu onClick={handleActionMenu}>
                <Menu.Item key="settings">Settings</Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  },
]

const BenefitVenueSettings = ({ benefitVenue, onChange }) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [allSettings, setAllSettings] = useState(null)
  const [editingSettings, setEdittingSettings] = useState(false)
  const [settings, setSettings] = useState(benefitVenue.settings)
  const [permittedResources, setPermittedResources] = useState()
  const [permittedBeneficiaryGroups, setPermittedBeneficiaryGroups] = useState()
  const [venueResources, setVenueResources] = useState({})
  const [benefitBeneficiaryGroups, setBenefitBeneficiaryGroups] = useState({})
  const [editingTables, setEditingTables] = useState(false)

  const status = {
    updateSettings: useStatus(acts.UPDATE_BENEFIT_VENUE_SETTINGS),
    updatePermittedResources: useStatus(acts.UPDATE_BENEFIT_VENUE_PERMITTED_RESOURCES),
    updatePermittedBeneficiaryGroups: useStatus(
      acts.UPDATE_BENEFIT_VENUE_PERMITTED_BENEFICIARY_GROUPS,
    ),
    fetchConsts: useStatus(acts.FETCH_CONSTS_BY_NAME),
    fetchVenueResources: useStatus(acts.FETCH_VENUE_RESOURCES),
    fetchBenefitBeneficiaryGroups: useStatus(acts.FETCH_BENEFICIARY_GROUPS),
  }

  useEffect(() => {
    dispatch(fetchConstsByName({ name: 'benefit-venue' })).then(consts => {
      setAllSettings(consts.settings)
    })

    dispatch(fetchVenueResources({ venueSig: benefitVenue.venue })).then(resources => {
      const mappedResources = resources.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
      }, {})
      setVenueResources(mappedResources)
    })

    dispatch(fetchBenefitBeneficiaryGroups({ benefitId: benefitVenue.benefit.id })).then(
      beneficiaryGroups => {
        const mappedBeneficiaryGroups = beneficiaryGroups.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {})
        setBenefitBeneficiaryGroups(mappedBeneficiaryGroups)
      },
    )
    setPermittedResources(benefitVenue.permittedResources.map(r => r.resource))
    setPermittedBeneficiaryGroups(
      benefitVenue.permittedBeneficiaryGroups.map(bg => bg.beneficiaryGroup),
    )

    return () => {
      dispatch(clearStatus(acts.UPDATE_BENEFIT_VENUE_SETTINGS))
      dispatch(clearStatus(acts.FETCH_CONSTS_BY_NAME))
      dispatch(clearStatus(acts.UPDATE_BENEFIT_VENUE_PERMITTED_RESOURCES))
      dispatch(clearStatus(acts.UPDATE_BENEFIT_VENUE_PERMITTED_BENEFICIARY_GROUPS))
    }
  }, [benefitVenue])

  useStatusMsg(status.fetchConsts, {
    error: 'Failed to fetch settings',
  })
  useStatusMsg(status.fetchVenueResources, {
    error: 'Failed to fetch venue resources',
  })
  useStatusMsg(status.fetchBenefitBeneficiaryGroups, {
    error: 'Failed to fetch beneficiary groups',
  })

  if (
    status.fetchConsts.pending ||
    status.fetchVenueResources.pending ||
    status.fetchBenefitBeneficiaryGroups.pending
  ) {
    return <LoadingOutlined />
  }

  if (!allSettings || Object.keys(allSettings).length === 0) {
    return <i>No settings available</i>
  }

  const onSaveSettings = () => {
    dispatch(updateBenefitVenueSettings({ benefitVenueId: benefitVenue.id, settings }))
      .then(updatedSettings => {
        setEdittingSettings(false)
        changer(updatedSettings)
      })
      .catch(() => {})
  }

  const onSaveTables = () => {
    Promise.all([
      dispatch(
        updateBenefitVenuePermittedResources({
          benefitVenueId: benefitVenue.id,
          resourceIds: permittedResources,
        }),
      ),
      dispatch(
        updateBenefitVenuePermittedBeneficiaryGroups({
          benefitVenueId: benefitVenue.id,
          beneficiaryGroupIds: permittedBeneficiaryGroups,
        }),
      ),
    ]).then(() => {
      setEditingTables(false)
    })
  }

  const onCancelSettingsEdit = () => {
    setEdittingSettings(false)
    setSettings(benefitVenue.settings)
  }

  const onCancelEditingTables = () => {
    setEditingTables(false)
    setPermittedResources(benefitVenue.permittedResources.map(r => r.id))
    setPermittedBeneficiaryGroups(benefitVenue.permittedBeneficiaryGroups.map(bg => bg.id))
  }

  const onSelectChange = (value, setter) =>
    setter(prev => {
      if (prev && prev.find(id => id === value)) {
        return prev
      }
      return [...prev, value]
    })

  const onRemove = (value, setter) => {
    setter(prev => prev.filter(v => v !== value))
  }

  let buttons = ({ editingSetter, onCancel, onSave, status, editing }) => {
    if (editing) {
      return (
        <div>
          <Button
            style={{ marginRight: theme.spacing[2] }}
            disabled={status.pending}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <CtaButton onClick={onSave} disabled={status.pending}>
            {status.pending ? 'Saving...' : 'Save'}
          </CtaButton>
        </div>
      )
    }
    return (
      <Button onClick={() => editingSetter(true)}>
        <EditFilled /> Edit
      </Button>
    )
  }

  return (
    <div style={{ padding: theme.spacing[2] }}>
      <MediumInfo
        title="Settings"
        button={buttons({
          editingSetter: setEdittingSettings,
          editing: editingSettings,
          onCancel: onCancelSettingsEdit,
          onSave: onSaveSettings,
          status: status.updateSettings,
        })}
      >
        <Settings
          allSettings={allSettings}
          settings={settings}
          editing={editingSettings}
          onChange={updatedSettings => setSettings(updatedSettings)}
        />
      </MediumInfo>
      <MediumInfo
        titile=""
        button={buttons({
          editingSetter: setEditingTables,
          editing: editingTables,
          onCancel: onCancelEditingTables,
          onSave: onSaveTables,
          status: status.updatePermittedResources,
        })}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: theme.spacing[3] }}>
            <Label style={{ fontSize: theme.font.size[5], marginBottom: theme.spacing[1] }}>
              Permitted schedule resources
            </Label>
            {editingTables && (
              <Select
                onChange={resourceId => onSelectChange(resourceId, setPermittedResources)}
                style={{ marginBottom: theme.spacing[2] }}
              >
                {venueResources &&
                  Object.keys(venueResources).map(resourceId => (
                    <Option
                      key={venueResources[resourceId].id}
                      value={venueResources[resourceId].id}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{venueResources[resourceId].name}</span>
                        <small style={{ color: 'gray' }}>{venueResources[resourceId].type}</small>
                      </div>
                    </Option>
                  ))}
              </Select>
            )}
            {permittedResources && permittedResources.length > 0 ? (
              permittedResources.map(resourceId => (
                <div
                  style={{ display: 'flex', alignItems: 'center', paddingLeft: theme.spacing[1] }}
                >
                  <span>{venueResources[resourceId] && venueResources[resourceId].name}</span>
                  {editingTables && (
                    <MinusCircleOutlined
                      style={{ color: 'red', marginLeft: theme.spacing[1] }}
                      onClick={() => onRemove(resourceId, setPermittedResources)}
                    />
                  )}
                </div>
              ))
            ) : (
              <>{!editingTables && <Noner none="No resources selected"></Noner>}</>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Label style={{ fontSize: theme.font.size[5], marginBottom: theme.spacing[1] }}>
              Permitted schedule beneficiary groups
            </Label>
            {editingTables && (
              <Select
                onChange={bgId => onSelectChange(bgId, setPermittedBeneficiaryGroups)}
                style={{ marginBottom: theme.spacing[2] }}
              >
                {benefitBeneficiaryGroups &&
                  Object.keys(benefitBeneficiaryGroups).map(bgId => (
                    <Option
                      key={benefitBeneficiaryGroups[bgId].id}
                      value={benefitBeneficiaryGroups[bgId].id}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{benefitBeneficiaryGroups[bgId].name}</span>
                        <small style={{ color: 'gray' }}>
                          {benefitBeneficiaryGroups[bgId].status}
                        </small>
                      </div>
                    </Option>
                  ))}
              </Select>
            )}
            {permittedBeneficiaryGroups && permittedBeneficiaryGroups.length > 0 ? (
              permittedBeneficiaryGroups.map(bgId => (
                <div
                  style={{ display: 'flex', alignItems: 'center', paddingLeft: theme.spacing[1] }}
                >
                  <span>
                    {benefitBeneficiaryGroups[bgId] && benefitBeneficiaryGroups[bgId].name}
                  </span>
                  {editingTables && (
                    <MinusCircleOutlined
                      style={{ color: 'red', marginLeft: theme.spacing[1] }}
                      onClick={() => onRemove(bgId, setPermittedBeneficiaryGroups)}
                    />
                  )}
                </div>
              ))
            ) : (
              <>{!editingTables && <Noner none="No beneficiary groups selected"></Noner>}</>
            )}
          </div>
        </div>
      </MediumInfo>
    </div>
  )
}

const BenefitVenues = ({ venue, openSettings, setBenefitVenue }) => {
  const theme = useTheme(ThemeContext)
  return (
    <Table
      size="small"
      columns={benefitVenueCols({ openSettings, setBenefitVenue })}
      dataSource={venue.facilitatedBenefits}
      pagination={false}
      style={{ marginTop: theme.spacing[2] }}
    />
  )
}

export const VenueLinks = ({ venue }) => {
  const theme = useTheme(ThemeContext)
  return (
    <>
      <div style={{ marginBottom: theme.spacing[2] }}>
        <a href={urlUtils.mainSiteVenueHomepage(venue.sid)}>
          Break the Love <LinkOutlined />
        </a>
      </div>
      <div>
        <a href={urlUtils.bosOrgVenueHomepage(venue.sid)}>
          BreakOS <LinkOutlined />
        </a>
      </div>
    </>
  )
}

export const VenueDetails = ({ venue }) => {
  const { info } = venue || {}
  const { attributes } = info || {
    attributes: { numberOfCourts: 0, surfaceTypes: [], sports: [], venueType: null },
  }

  return (
    <>
      <div>
        <Noner none="No phone number">{venue.phoneNumber}</Noner>
      </div>
      <div>
        <Noner none="No email">{venue.email}</Noner>
      </div>
      <div>
        <Noner none="No official website">
          {venue.officialWebsiteUrl ? (
            <a href={venue.officialWebsiteUrl}>
              Official website <LinkOutlined />
            </a>
          ) : null}
        </Noner>
      </div>
      <div>
        <Noner none="No official instagram">
          {venue.instagramUrl ? (
            <a href={venue.instagramUrl}>
              Official instagram <LinkOutlined />
            </a>
          ) : null}
        </Noner>
      </div>
      <div style={{ marginTop: '1em' }}>
        <div>
          <strong>Number of Courts:</strong>{' '}
          <Noner none="Not specified">{attributes?.numberOfCourts}</Noner>
        </div>
        <div>
          <strong>Venue Type:</strong>{' '}
          <Noner none="Not specified">
            <Tag>{attributes?.venueType?.replace('_', ' ')}</Tag>
          </Noner>
        </div>
        <div>
          <strong>Surface Types:</strong>{' '}
          <Noner none="Not specified">
            {attributes?.surfaceTypes?.map(type => (
              <Tag key={type}>{type.replace('_', ' ')}</Tag>
            ))}
          </Noner>
        </div>
        <div>
          <strong>Sports:</strong>{' '}
          <Noner none="Not specified">
            {attributes?.sports?.map(sport => (
              <Tag key={sport}>
                <SportDisplay sport={sport} />
              </Tag>
            ))}
          </Noner>
        </div>
      </div>
    </>
  )
}

const Venue = () => {
  const { venueSig } = useParams()
  const dispatch = useDispatch()
  const theme = useTheme(ThemeContext)
  const [venue, setVenue] = useState(null)
  const [editing, setEditing] = useState(false)
  const [benefitVenue, setBenefitVenue] = useState(null)
  const [benefitVenueSettings, setBenefitVenueSettings] = useState(false)
  const status = {
    fetch: useStatus(acts.FETCH_VENUE),
  }

  useEffect(() => {
    dispatch(fetchVenue({ venueSig })).then(v => setVenue(v))
    return () => {
      dispatch(clearStatus(acts.FETCH_VENUE))
    }
  }, [])

  if (!venue || status.fetch.pending) {
    return <LoadingOutlined />
  }

  const onAddAvailableLesson = lesson => {
    if (lesson) {
      setVenue(prev => ({
        ...prev,
        availableLessons: [...prev.availableLessons, lesson],
      }))
    }
  }

  const onRemoveAvailableLesson = lesson => {
    if (lesson) {
      setVenue(prev => ({
        ...prev,
        availableLessons: prev.availableLessons.filter(al => al.id !== lesson.id),
      }))
    }
  }

  const onPackageSetsChange = updatedSets => {
    setVenue(prev => ({ ...prev, packageSets: updatedSets }))
  }

  const numRes = Array.isArray(venue.resources) ? venue.resources.length : 0
  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.ORGS()}>Orgs</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.admin.ORG(venue.org.sid)}>{venue.org.name}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.admin.VENUE(venueSig)}>{venue.name}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Head
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: theme.spacing[3],
          alignItems: 'center',
        }}
      >
        <Title>{venue.name}</Title>
        <Dropdown
          overlay={<VenueActionsMenu venue={venue} onEdit={() => setEditing(true)} />}
          trigger={['click']}
        >
          <CtaButton>
            Actions
            <DownOutlined />
          </CtaButton>
        </Dropdown>
      </Head>
      <div>
        <Attr name="Id">
          <ID>{venue.id}</ID>
        </Attr>
        <Attr name="Sid">
          <div>{venue.sid}</div>
        </Attr>
        <Attr name="Bookability">
          <div>
            <Tag>{venue.bookability}</Tag>
          </div>
        </Attr>
        <Attr name="Links">
          <VenueLinks venue={venue} />
        </Attr>
        <Attr name="Details">
          <VenueDetails venue={venue} />
        </Attr>
        <Attr name="Timezone">
          <div>{venue.tz}</div>
        </Attr>
        <Attr name="Address">
          <VenueAddress venue={venue} />
        </Attr>
        <Attr name="Images">
          <VenueImages venue={venue} />
        </Attr>
        <Attr name="Booking metrics">
          <BookingMetrics venue={venue} />
        </Attr>
        <Attr name="Resources" label={`${numRes} resource${numRes === 1 ? '' : 's'}`}>
          <VenueResources venue={venue} />
        </Attr>
        <Attr name="Package sets">
          <PackageSets sets={venue.packageSets} onChange={onPackageSetsChange} />
        </Attr>
        <Attr name="Available lessons">
          <VenueAvailableLessons
            venue={venue}
            onAdd={onAddAvailableLesson}
            onRemove={onRemoveAvailableLesson}
          />
        </Attr>
        <Attr name="Benefit venues">
          <BenefitVenues
            venue={venue}
            openSettings={() => setBenefitVenueSettings(true)}
            setBenefitVenue={setBenefitVenue}
          />
        </Attr>
        <Attr name="Venue homepage">
          <div>
            <VenueHomepage venue={venue} />
          </div>
        </Attr>
        <VenueSettings
          venue={venue}
          onChange={updateSettings => setVenue(prev => ({ ...prev, settings: updateSettings }))}
        />
      </div>
      {editing && (
        <EditVenue
          venue={venue}
          complete={updatedVenue => {
            if (updatedVenue) {
              setVenue(updatedVenue)
            }
            setEditing(false)
          }}
        />
      )}
      {/* Benefit venue settings modal */}
      <Modal
        open={benefitVenueSettings}
        okText="Close"
        onOk={() => setBenefitVenueSettings(false)}
        okType="default"
        onCancel={() => setBenefitVenueSettings(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <BenefitVenueSettings benefitVenue={benefitVenue} />
      </Modal>
    </PaddedContainer>
  )
}

export default Venue
